import React, { Component } from 'react';
import { TreeSelect as AntdTreeSelect } from 'antd';

export default class TreeSelect extends Component {
  render() {
    const {
      treeData = [],
      fieldNames = {
        title: 'title',
        value: 'value',
        key: 'key',
        children: 'children',
      },
    } = this.props;
    const titleReg = new RegExp(fieldNames.title, 'g');
    const valueReg = new RegExp(fieldNames.value, 'g');
    const keyReg = new RegExp(fieldNames.key, 'g');
    const childrenReg = new RegExp(fieldNames.children, 'g');
    const data = JSON.parse(
      JSON.stringify(treeData)
        .replace(titleReg, 'title')
        .replace(valueReg, 'value')
        .replace(keyReg, 'key')
        .replace(childrenReg, 'children')
    );
    return (
      <AntdTreeSelect
        searchPlaceholder="请输入搜索"
        {...this.props}
        treeNodeFilterProp="title"
        treeData={data}
      />
    );
  }
}

TreeSelect.defaultProps = {
  allowClear: true,
  multiple: false,
  showSearch: true,
};
