/* eslint-disable global-require,camelcase,import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import fetch from './util/fetch';
import './styles/core.scss';
import './styles/dark.scss';
import './util/fix';
import createStore from './store/createStore';
import { getBaseUrl } from './util';
import { history } from './store/location';

if (!__LOCAL__) {
  Sentry.init({
    dsn: 'https://08cada91648a476f86f41a8bd38b1948@am.jwsmed.com/5',
    environment: __ONLINE__ ? 'production' : 'test',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    Sentry.setUser({
      id: accessToken,
      username: localStorage.getItem('username'),
    });
  }
}

message.config({
  maxCount: 1,
});

// ========================================================
// fetch init
// ========================================================

fetch.init({
  baseUrl: getBaseUrl,
  monitor: {
    start: () => {},
    end: () => {},
    error: () => {},
  },
  addAuth: () => localStorage.getItem('accessToken') || '',
  hash: process.env.version,
});

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.__INITIAL_STATE__;
const store = createStore(initialState);

window.storeManager = store;
// user logout or switch env should clear
window.storeManager.clear = (excludes = []) => {
  const state = window.storeManager.getState() || {};
  const keys = Object.keys(state);
  keys.forEach(key => {
    if (excludes.indexOf(key) === -1) {
      state[key] = undefined;
    }
  });
};

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

let render = () => {
  const App = require('./containers/AppContainer').default;
  ReactDOM.render(
    // 通过 ConfigProvider 让所有的后代组件接收到 store
    <ConfigProvider locale={zhCN}>
      <App store={store} />
    </ConfigProvider>,
    MOUNT_NODE
  );
};

// This code is excluded from production bundle

const RedBox = __LOCAL__ ? require('redbox-react').default : null;

if (__LOCAL__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = error => {
      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp();
      } catch (error) {
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept(
      [
        './containers/AppContainer',
        './routes/index',
        './store/location.js',
        './store/createStore.js',
      ],
      () =>
        setImmediate(() => {
          ReactDOM.unmountComponentAtNode(MOUNT_NODE);
          render();
        })
    );
  }
}

// ========================================================
// Go!
// ========================================================
render();

const dark = JSON.parse(localStorage.getItem('darkTheme') || '0');
if (dark == '1') {
  document.body.classList.add('dark');
}

window.addEventListener(
  'keydown',
  e => {
    if (e.keyCode === 27) {
      // escape
      history.goBack();
    }
  },
  false
);

// 浏览器通知
// 本来是要自己搭一个web push服务器，但发送通知是报错，可能是https问题
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

window.sendNotification = () => {};

if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(swReg => {
      return swReg.pushManager
        .getSubscription()
        .then(async function subCallback(subscription) {
          const isSubscribed = !(subscription === null);

          if (isSubscribed) {
            return subscription;
          }
          const res = await fetch(
            'https://serviceworke.rs/push-payload/vapidPublicKey',
            {},
            {
              method: 'get',
              noAuth: true,
            }
          );
          let text = '';
          if (res.text) {
            text = await res.text();
            const applicationServerKey = urlB64ToUint8Array(text);
            return swReg.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey,
            });
          }
          return null;
        })
        .then(subscription => {
          // 一般不要这么做，全局变量
          window.sendNotification = (payload = {}, delay = 0) => {
            fetch(
              'https://serviceworke.rs/push-payload/sendNotification',
              {
                subscription,
                payload: JSON.stringify(payload),
                delay, // 秒
                ttl: '0',
              },
              {
                noAuth: true,
              }
            );
          };
        });
    })
    .catch(e => {
      if (e.name === 'NotAllowedError') {
        message.error('请设置允许浏览器通知，不然可能无法接收到推送');
      }
    });
} else {
  message.info(
    '该浏览器不支持消息推送，请使用谷歌或火狐浏览器（谷歌浏览器需翻墙才能收到推送）'
  );
}

export default store;

export { store };
