/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from '../utils';
import './style.scss';

const AntdTextArea = Input.TextArea;

export default class TextArea extends Component {
  constructor(props) {
    super(props);
    let value = props.value || undefined;
    if (typeof value === 'number') {
      value = `${value}`;
    }
    this.state = { value, cur: (value || '').length };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      let value = nextProps.value || undefined;
      if (typeof value === 'number') {
        value = `${value}`;
      }
      const cur = (value || '').length;
      this.setState({ value, cur });
    }
  }

  handleChange(e) {
    const value = e.target.value;
    this.props.onChange(value);
    const cur = (value || '').length;
    this.setState({ value, cur });
  }

  render() {
    const { disabled, max, compRef, ...restProps } = this.props;
    const { value, cur } = this.state;
    const numVisible = max && !disabled;
    return disabled ? (
      <div className="textarea-disabled">
        {isEmpty(value) ? (
          <span className="fe-blank-holder">-</span>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, '<br />') }}
          />
        )}
      </div>
    ) : (
      <div style={{ position: 'relative' }}>
        <AntdTextArea
          {...restProps}
          ref={compRef}
          value={value}
          onChange={this.handleChange.bind(this)}
        />
        <div
          className="ant-textarea-num"
          style={{ display: !numVisible ? 'none' : 'block' }}
        >
          <span className="ant-textarea-num-pre">{cur}</span>
          {' / '}
          <span className="ant-textarea-num-post">{max}</span>
        </div>
      </div>
    );
  }
}

TextArea.propTypes = {
  max: PropTypes.number,
};

TextArea.defaultProps = {
  max: undefined,
};
