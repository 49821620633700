import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Layout, Spin, Badge } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './style.scss';
import { getSideMenus } from '../../selectors';
import { common } from '../../store/common';
import respond from '../../decorators/Responsive';

const { SubMenu } = Menu;
const { Sider } = Layout;

const LinkWrapper = props => {
  const Com = props.to ? Link : 'a';
  return (
    <Com style={{ display: 'block' }} {...props}>
      {props.children}
    </Com>
  );
};

class SideMenu extends Component {
  constructor(props) {
    super(props);
    props.menuLoad().then(() => {});
    this.state = {
      collapsed: false,
    };
    // 查询未阅读消息
    this.props.evaluateUntreated();
  }

  onCollapse = collapsed => {
    this.setState({
      collapsed,
    });
    if (!this.state.collapsed) {
      this.props.collapseSubMenu();
    }
  };

  onClick({ key }) {
    this.props.clickMenuItem(key);
  }

  onTitleClick({ key }) {
    this.props.clickSubMenu(key);
  }

  renderSubMenuTitle = menu => {
    return (
      <span>
        {menu.icon && <Icon type={menu.icon} />}
        <span>
          <Badge
            dot={menu.name === '待办事项' && this.props.evaluateBadge}
            offset={[5, 0]}
          >
            <span className="menu-text">{menu.name}</span>
          </Badge>
        </span>
      </span>
    );
  };

  renderMenu(menus) {
    // recursion to render the sideMenu
    // console.log(menus);
    return menus.map(menu => {
      const children = (menu.children || []).filter(i => i.type != 2);
      if (children.length > 0) {
        return (
          <SubMenu
            key={menu.id}
            style={{ display: menu.hidden ? 'none' : 'block' }}
            title={
              // menu.parentId !== 0 防止点一级菜单是刷新页面
              menu.href && menu.parentId !== 0 ? (
                <LinkWrapper
                  to={{
                    pathname: menu.href,
                    state: { action: 'RESET' },
                  }}
                >
                  {this.renderSubMenuTitle(menu)}
                </LinkWrapper>
              ) : (
                this.renderSubMenuTitle(menu)
              )
            }
            onTitleClick={this.onTitleClick.bind(this)}
          >
            {this.renderMenu(children)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          key={menu.id}
          style={{ display: menu.hidden ? 'none' : 'block' }}
        >
          <LinkWrapper
            to={{
              pathname: menu.href,
              state: { action: 'RESET' },
            }}
          >
            <Badge
              dot={menu.name === '待评价回访' && this.props.evaluateBadge}
              offset={[5, 0]}
            >
              {menu.icon && <Icon type={menu.icon} />}
              <span className="menu-text">{menu.name}</span>
            </Badge>
          </LinkWrapper>
        </Menu.Item>
      );
    });
  }

  render() {
    const {
      menuData,
      selectedKeys,
      openKeys,
      changeOpen,
      expand,
      loading,
    } = this.props;
    return (
      <Sider
        collapsible
        collapsedWidth={expand ? 80 : 0}
        breakpoint="sm"
        width={200}
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        <Spin size="large" spinning={loading} />
        <div className="flex flex-v" style={{ height: '100%' }}>
          <div className="logo-wrapper flex flex-js flex-c">
            <div className="logo">
              <img alt="" src="/logo2.png" />
            </div>
          </div>
          <Menu
            style={{ overflowY: 'auto' }}
            mode="inline"
            theme="dark"
            inlineIndent={20}
            selectedKeys={[...selectedKeys]}
            openKeys={openKeys}
            onClick={this.onClick.bind(this)}
            onOpenChange={changeOpen}
          >
            {this.renderMenu.bind(this)(menuData)}
          </Menu>
        </div>
      </Sider>
    );
  }
}

SideMenu.propTypes = {
  menuData: PropTypes.array.isRequired,
  selectedKeys: PropTypes.array,
  openKeys: PropTypes.array,
  menuLoad: PropTypes.func.isRequired,
  clickSubMenu: PropTypes.func.isRequired,
  clickMenuItem: PropTypes.func.isRequired,
};

SideMenu.defaultProps = {
  selectedKeys: [],
  openKeys: [],
};

const mapStateToProps = state => ({
  selectedKeys: state.common.selectedKeys || [],
  openKeys: state.common.openedKeys || [],
  menuData: getSideMenus(state),
  loading: state.common.loading,
  evaluateBadge: state.common.evaluateBadge,
});

const mapDispatchToProps = {
  menuLoad: common.loadMenu,
  clickSubMenu: common.clickSubMenu,
  clickMenuItem: common.clickMenuItem,
  collapseSubMenu: common.collapseSubMenu,
  changeOpen: common.changeOpen,
  evaluateUntreated: common.evaluateUntreated,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(respond(SideMenu));
